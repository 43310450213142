<script setup>
import Logo from '~/components/molecules/Logo.vue'
import CartIcon from '~/components/molecules/CartIcon.vue'
import HorizontalMenu from '~/components/molecules/HorizontalMenu.vue'
import BurgerIcon from '~/components/icons/BurgerIcon.vue'
import MessageIcon from '~/components/icons/MessageIcon.vue'
import {useStartupStore} from "~/store/sturtupStore";
import {defineAsyncComponent} from "vue";
import SearchIcon from "~/components/icons/SearchIcon.vue";
import {useCartStore} from "~/store/cartStore.js";
import ExpandIcon from "~/components/icons/ExpandIcon.vue";
import {useRouter, useRoute} from "vue-router";
import Text from "~/components/atoms/Text.vue";

const router = useRouter();
const route = useRoute();
const cartStore = useCartStore();

import Searching from "~/components/organisms/Searching.vue";

const startupStore = useStartupStore();
const topMenu = startupStore.top_menu
const header_left = startupStore.header_left
const header_right = startupStore.header_right

defineProps({
  mode: {
    type: String,
    default: 'default'
  }
})

let containerActive = ref(false)
let maskActive = ref(false)
let searchInitialized = ref(false)

watch(() => maskActive.value, val => {
  if (val) {
    setTimeout(() => {
      containerActive.value = true
    }, 50)
  } else {
    containerActive.value = false
  }
})

watch(() => containerActive.value, val => {
  if (!val) {
    setTimeout(() => {
      maskActive.value = false
    }, 200)
  }
})
</script>

<template>
  <div class="container static">
    <div class="top-header row full-width" :class="[mode]">
      <div class="icons-container no-flex row g-32">
        <template v-if="mode!=='checkout' || route.path == '/checkout/thank-you/'">
          <div class="fac">
            <BurgerIcon class="clickable" width="28px" height="28px" @click="startupStore.openMenu"/>
          </div>
          <Searching v-if="searchInitialized"/>
          <SearchIcon @click="searchInitialized=true" v-else style="cursor: pointer;" width="28px" height="28px"/>
        </template>
        <template v-else-if="$device.isMobile">
          <div class="back circle flex-center" @click="router.back()">
            <ExpandIcon style="transform: rotate(180deg)"/>
          </div>
        </template>
        <template v-else>
          <div class="row g-4 clickable" @click="router.back()">
            <ExpandIcon style="width:16px; height:16px; transform: rotate(180deg)"/>
            <Text tag="b">Tillbaka</Text>
          </div>
        </template>
      </div>
      <div class="logo">
        <Logo :mode="mode"/>
      </div>
      <div class="icons-container no-flex row g-32" v-if="mode!=='checkout' || !$device.isMobile">
        <template v-if="mode==='checkout'">
          <a :href="$t('menu.items.contact-us.link')">
            <MessageIcon width="28px" height="28px" stroke="black"/>
          </a>
        </template>
        <template v-else>
          <nuxt-link :to="$t('menu.items.contact-us.link')">
            <MessageIcon width="28px" height="28px" stroke="black"/>
          </nuxt-link>
        </template>
        <CartIcon :cart-count="cartStore.content?.total_count" @click="cartStore.openCart()"/>
      </div>
    </div>

    <div class="search-hints" :class="{'hidden':mode==='checkout'}">
      <div class="search-hints-items header-item-side hidden-smd">
        <HorizontalMenu :no-padding="true" v-if="header_left"
                        :items="[{title:header_left.name,link:'/'+header_left.link}]"/>
      </div>

      <div class="search-hints-items static">
        <HorizontalMenu :hide-on-mobile="false" @openHiddenContainer="maskActive=true"
                        @closeHiddenContainer="containerActive=false" :maskActive="maskActive"
                        :items="topMenu.map(item => ({title:item.name, link:'/'+item.slug, children:item.children}))"/>
      </div>

      <div class="search-hints-items header-item-side hidden-smd last">
        <HorizontalMenu :no-padding="true" v-if="header_right"
                        :items="[{title:header_right.name,link:'/'+header_right.link}]"/>
      </div>
    </div>
    <div class="hidden-search-hints-items-container" :class="{active:maskActive,'content-active':containerActive}">
      <div class="white-desk"></div>
      <div class="mask"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.back {
  width: 38px;
  height: 38px;
  box-shadow: 0px 4px 45px 0px rgba(249, 249, 249, 1);
  background: $white;
}

.hidden {
  display: none !important;
}

.icons-container {
  @include smd {
    gap: 40px;
  }

  a {
    display: flex;
  }
}

svg {
  @include mobile {
    height: 24px;
  }
}

.fac {
  justify-content: center;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  width: 130px;

  @include smd {
    height: 36px;
    width: auto;
  }

  &:deep(svg) {
    @include mobile {
      height: 36px;
    }
  }
}


.search-hints-items {
  flex: unset;

  @include smd {
    max-width: 100%;
    overflow-x: auto;
  }
}

.search-hints {
  flex-direction: row;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  font-size: 13px;
  min-height: 50px;

  @include mobile {
    margin-right: -16px;
  }

  .header-item-side {
    width: 150px;

    &.last {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

}

.top-header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    height: 56px;
    position: fixed;
    top: 24px;
    z-index: 99999;
    background: white;
    left: 0;
    right: 0;
    padding: 0 16px;
  }

  &.checkout {
    @include mobile {
      background: $grey;
      top: 0;
      height: 64px;
    }
  }

  &.clean {
    background: #f5f5f5;
  }
}

.hidden-search-hints-items-container {
  display: none;
  min-height: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1001;
  justify-content: center;
  align-items: center;
  margin-top: -24px;
  top: 185px;

  &.active {
    height: 100vh;
    display: flex;

    @include smd {
      display: none;
    }
  }

  .white-desk, .mask {
    width: 100%;
  }

  .white-desk {
    background: $white;
    height: 0;
    transition: all 0.2s ease-in-out;
    max-height: 0;
  }

  &.content-active .white-desk {
    max-height: 130px;
    height: 130px;
  }

  .mask {
    flex: 1;
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>
